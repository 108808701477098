/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.container {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #666;
  padding: 0 24px;
  max-width: 393px;
  margin: 12px auto 36px auto;
}

.panda {
  width: 160px;
  height: auto;
}

.title {
  color: #394B58;
  font-size: 28px;
  margin: 24px 0 12px 0;
}

.intro {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.buttons {
  margin: 36px 0 0 0;
  width: 100%;
}

.button {
  color: #0374B5;
  font-size: 16px;
  line-height: 24px;
  padding: 7px 10px;
  border: 1px solid #0374B5;
  border-radius: 4px;
  display: block;
  text-align: center;
  text-decoration: none;
}
.button + .button {
  margin-top: 24px;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
